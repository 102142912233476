import Layout from "../Layout/Layout";
import { StyledCareer } from "./Career.Styled";
import heroImage from "../../assets/image/career/hero.jpg";
import { useSelector } from "react-redux";
import props from "../../redux/props";
import History from "./History/History";
import Boxes from "./Boxes/Boxes";
import Reasons from "./Reasons/Reasons";
import Banner from "./Banner/Banner";
import People from "./People/People";
import OpenPositions from "./OpenPositions/OpenPositions";
import Wires from "./Wires/Wires";
import Hero from "../SubComponents/Hero/Hero";

const Career = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  return (
    <Layout authRequired={false}>
      <StyledCareer>
        <Hero
          title={translation.career.title}
          by={translation.career.by}
          bg={heroImage}
          top
        />
        <Boxes />
        <History />
        <Wires />
        <Reasons />
        <Banner />
        <People />
        <OpenPositions />
      </StyledCareer>
    </Layout>
  );
};

export default Career;
