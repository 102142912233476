import {
  List,
  Item,
  Image,
  Text,
  Title,
  Description,
  ImageWrapper,
} from "./Wires.Styled";
import nonfire from "../../../assets/image/career/wires/nonfire.png";
import ad from "../../../assets/image/career/wires/adq.png";
import adqEinblasoptimiert from "../../../assets/image/career/wires/adq-einblasoptimiert.png";
import { useSelector } from "react-redux";
import props from "../../../redux/props";
import { Container } from "@think-internet/ui-components";
import { Headline } from "./Wires.Styled";

const Wires = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);
  const images = [nonfire, ad, adqEinblasoptimiert];

  return (
    <Container>
      <Headline>{t.career.wires.headline}</Headline>
      <List>
        {t.career.wires.items.map((w, i) => (
          <Item key={w.href} href={w.href} target="_blank">
            <ImageWrapper>
              <Image src={images[i]} />
            </ImageWrapper>
            <Text>
              <Title>{w.name}</Title>
              <Description>{w.description}</Description>
            </Text>
          </Item>
        ))}
      </List>
    </Container>
  );
};

export default Wires;
