import { Container } from "@think-internet/ui-components";
import styled from "styled-components";

export const BoxesRelativeWrapper = styled(Container)`
  position: relative;
`;

export const StyledBoxes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  width: calc(100% - 30px);
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    top: 0px;
    position: relative;
    width: 100%;
  }
`;
export const Box = styled.div`
  background-color: ${({ theme, gray }) =>
    gray ? theme.color.background.fifth : theme.color.background.bayka};
  padding: 25px 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;
export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: "bold";
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 15px;
`;
export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.md};
  color: ${({ theme }) => theme.color.font.secondary};
  text-align: center;
`;
