import styled from "styled-components";

export const Headline = styled.div`
  margin-top: 100px;
  font-size: ${({ theme }) => theme.font.size.lg};
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 50px;
`;

export const Item = styled.a`
  display: flex;
  gap: 5%;
  align-items: center;
  text-decoration: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;

export const ImageWrapper = styled.div`
  width: 25%;
  flex-shrink: 0;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;
export const Image = styled.img`
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 50px;
    width: auto;
  }
`;

export const Text = styled.div``;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  font-family: "bold";
  color: ${({ theme }) => theme.color.font.thirdary};
`;

export const Description = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.thirdary};
`;
