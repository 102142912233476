//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  header: {
    pages: [
      {
        name: "Startseite",
        href: "/",
      },
      {
        name: "Über uns",
        href: "/ueber-uns",
      },
      { name: "Kontakt", href: "/kontakt" },
      {
        name: "Beschwerdesystem",
        href: "/beschwerdesystem",
      },
    ],
  },

  contact: {
    title: ["Kontakt."],
    headline: "KONTAKT",
    form: {
      label: "Melde dich bei uns",
      cta: "Senden",
      success:
        "Ihre Nachricht wurde erfolgreich verschickt. Wir melden uns schnellstmöglich bei Ihnen.",
      error:
        "Ihre Nachricht konnte nicht verschickt werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
    },
  },

  career: {
    title: ["TEAMGEIST.", "LEIDENSCHAFT.", "QUALITÄT."],
    by: "Wir sind die BGF, seit über 35 Jahren!",
    cta: "Weitere Infos >",
    openPositions: {
      headline: "BEWIRB DICH JETZT",
      jobs: {
        publishedOn: "veröffentlicht: ",
        cta: "ZUR STELLENAUSSCHREIBUNG",
      },
    },
    history: {
      text: "Praktisch zeitgleich mit dem Beginn der Glasfaserzeit in der Nachrichtentechnik bauten auch die Bayerischen Kabelwerke eigene Produktionskapazitäten für Glasfaserkabel und LWL-Kabel auf. Die BGF wurde 1986 von der Bayka AG gemeinsam mit vier weiteren mittelständischen Unternehmen in Berlin gegründet.",
      link: {
        text: "Mehr hier...",
        href: "/ueber-uns",
      },
    },
    wires: {
      headline: "Produkte",
      items: [
        {
          name: "BayCom® nonfire B2ca OFC TUSI",
          href: "https://www.bayka.de/produkt/?146303509",
          description:
            "halogenfreie und hochflammwidrige Lichtwellenleiter Universalkabel mit metallischer Bewehrung zum Fernsprechen und zur Datenübertragung für den Einsatz in Bereichen mit hohen Anforderungen an den Brandschutz nach BauPVO Brandklasse B2ca.",
        },
        {
          name: "BayCom® A-DQ(ZN)2Y(SR)2Y",
          href: "https://www.bayka.de/produkt/?38273898",
          description:
            "Bewehrtes trocken gefülltes LWL-Außenkabel mit Zulassung der Deutschen Bahn zur allgemeinen Verkabelung im Streckenbereich für die Verlegung in Rohr- und Trogkanälen.",
        },
        {
          name: "BayCom® A-DQ(ZN)2Y (einblasoptimiert)",
          href: "https://www.bayka.de/produkt/?132927506",
          description:
            "Leichtes metallfreies LWL-Außenkabel ohne Nagetierschutz, als Anschluss- und Verbindungskabel in Weitverkehrs- und Ortsnetzen, in Nebenstellenanlagen, zum Fernsprechen und zur Übertragung von Daten",
        },
      ],
    },
    boxes: [
      {
        headline: "Tragfähige Innovationen brauchen ein stabiles Fundament.",
        text: "Auch in turbulenten Zeiten hat uns der Zusammenhalt innerhalb der Bayka Familie zum Erfolg getragen. Werte wie Ehrlichkeit, Nachhaltigkeit, Zuverlässigkeit und Respekt sind tief in unserer Unternehmens-DNA verankert. Faire Arbeitsbedingungen, Gleichbehandlung, kurze Wege und Offenheit von Seiten der Geschäftsleitung tragen zu einem wertschätzenden Umgang untereinander bei.",
      },
      {
        headline: "Gekommen, um zu bleiben",
        text: [
          "Sicherheit im Job und gegenseitiges Vertrauen schaffen eine Win-win-Situation für alle, denn sowohl Mitarbeitende als auch Geschäftsführung können langfristig planen. Als Teil der inhabergeführten, finanziell solide aufgestellten Firmen-Gruppe haben wir uns den Charakter eines Familienunternehmens erhalten. Wir fertigen ausschließlich „made in Germany“ und sind ein zuverlässiger und innovativer Partner für unsere Kunden in aller Welt. Unser Team ist eine bunte Mischung aus jung und erfahren und freut sich auf Ihre Bewerbung.",
        ],
      },
    ],
    reasons: {
      cta: "Zu unseren Stellenangeboten",
      headline: "11 GRÜNDE TEAMMITGLIED ZU WERDEN",
      pointTextToggle: {
        open: "mehr",
        close: "weniger",
      },
      points: [
        {
          headline: "Gesundheitsvorsorge",
          text: "Arbeitsmedizinische Vorsorge, Unfallverhütung, Arbeitsschutz haben den höchsten Stellenwert im Unternehmen.",
        },
        {
          headline: "Betriebliches Vorschlagswesen",
          text: "Wer kennt die Potentiale besser als die eigene Belegschaft? Deshalb gibt es ein Prämiensystem für Verbesserungsvorschläge.",
        },
        {
          headline: "Urlaub + Urlaubsgeld",
          text: "Wichtig ist, der Urlaub (28-30 Tage) gehört dem Mitarbeiter* – ständige Erreichbarkeit muss nicht sein.",
        },
        {
          headline: "Weihnachtsgeld",
          text: "Jedem Mitarbeiter fließt eine betriebliche Sonderzahlung zu.",
        },
        {
          headline: "Sonderurlaub",
          text: "Bei besonderen Ereignissen wie Hochzeit, Geburt oder Todesfall in der Familie, gewähren wir Sonderurlaub.",
        },
        {
          headline: "Arbeitskleidung",
          text: "Wir stellen unseren Mitarbeitern im gewerblichen Bereich über einen Mietwäsche-Service die Arbeitskleidung kostenfrei zur Verfügung.",
        },
        {
          headline: "Betriebsrat",
          text: "Wir pflegen mit unserem 3-köpfigen Betriebsrat ein offenes und ehrliches Miteinander.",
        },
        {
          headline: "Weihnachtsfeier",
          text: "Wir fördern mit Geldzuschüssen Treffen der Mitarbeiter abseits des Arbeitsalltags.",
        },
        {
          headline: "Weiterbildung",
          text: "Wir legen großen Wert darauf, dass sich unsere Mitarbeiter weiterbilden, ob bei internen Schulungsmaßnahmen oder externen Seminaren und Workshops.",
        },
        {
          headline: "Fahrradleasing",
          text: "Gut für Umwelt und Gesundheit. Das sogenannte „Dienstradleasing“ (Fahrradleasing) funktioniert über die Entgeltumwandlung. Ab 7. Monat Zugehörigkeit.",
        },
        {
          headline: "Corporate Benefits",
          text: "Rabatte und tolle Angebote ein umfangreiches Programm und dauerhafte Vergünstigungen.",
        },
      ],
      genderHint:
        "*Gender-Hinweis: Aus Gründen der besseren Lesbarkeit wird auf die gleichzeitige Verwendung der Sprachformen männlich, weiblich und divers (m/w/d) verzichtet. Sämtliche Personenbezeichnungen gelten gleichermaßen für alle Geschlechter.",
    },
    banner: {
      line1: "“Wo die Bedürfnisse der Welt mit deinen Talenten",
      line2: "zusammentreffen, dort liegt deine Berufung.” Aristoteles",
    },
    people: {
      headline: "Unsere Geschäftsleitung",
      subHeadline:
        "Gemeinsam mit Ihrem Team, verkörpern die drei Geschäftsführer die Werte und Ziele der ETL Protax.",
      list: [
        {
          firstname: "Andreas",
          lastname: "Ketzler",
          title: "Werkleiter",
          text: null,
          mail: "ketzler.a@bgf-kabel.de",
          isGF: true,
        },
      ],
    },
  },

  aboutUs: {
    title: ["Wir sind die BGF!"],
    by: "seit über 35 Jahren!",
    text: [
      "Die Berliner Glasfaserkabel GmbH, kurz BGF, ist der Bayka Produktionsstandort für Glasfaserkabel und Lichtwellenleiterkabel. Innovative Kabel, wie z. B. Kabel mit Umkehrpunktmarkierung, LWL-Hybridkabel und kundenspezifische Kabellösungen ergänzen das Standardprogramm.",
      "Praktisch zeitgleich mit dem Beginn der Glasfaserzeit in der Nachrichtentechnik bauten auch die Bayerischen Kabelwerke eigene Produktionskapazitäten für Glasfaserkabel und LWL-Kabel auf.",
      "Die BGF wurde 1986 von der Bayka AG gemeinsam mit vier weiteren mittelständischen Unternehmen am Standort Nunsdorfer Ring 12 in Berlin gegründet. Die Produktionshallen und der Maschinenpark waren damals speziell auf den Bedarf von Deutscher Bundespost und Deutscher Bundesbahn abgestimmt.",
      "Wenige Jahre später reichten die Produktionsflächen nicht mehr aus. 2013 bezog die BGF daher unter der Verantwortung der Bayerischen Kabelwerke AG in der Wilhelminenhofstraße einen neuen Fertigungsstandort mit erweiterten und optimierten Produktionsmöglichkeiten. Auf diesem traditionsreichen Areal setzte die BGF mit modernen Produkten und Anlagen nun fort, was 1897 mit dem Kabelwerk Oberspree KWO begann.",
      "Die Grundfläche des neuen Produktionsstandorts verdoppelte sich mit dem Umzug und ermöglichte einen größeren, modernisierten Maschinenpark. Eine neue Bündeladerlinie, eine neue SZ-Verseilanlage sowie eine weitere Mantellinie erhöhten die Produktionskapazitäten und trugen maßgeblich zur Erweiterung des Produktsortiments bei. Zukünftigen Produktionserweiterungen sind auf dem weitläufigen Gelände der KWO kaum Grenzen gesetzt.",
    ],
    location: {
      map: {
        headline: "Standort",
        name: "Berliner Glasfaserkabel GmbH",
        street: "Wilhelminenhofstr. 76/77",
        city: "12459 Berlin",
      },
      directions: {
        headline: "Hinweis zur Anfahrt",
        name: "Die Zufahrt befindet sich schräg gegenüber der Klarastraße.",
      },
    },
  },

  imprint: {
    headline: "Impressum",
  },

  dataProtection: {
    headline: "Datenschutz",
  },

  footer: {
    legal: [
      { name: "Impressum", href: "https://www.bayka.de/impressum/" },
      {
        name: "Datenschutz",
        href: "https://www.bayka.de/datenschutzerklaerung/",
      },
      {
        name: "Haftungsausschluss",
        href: "https://www.bayka.de/haftungsausschluss/",
      },
      {
        name: "AVB",
        href: "https://www.bayka.de/allgemeine-verkaufsbedingungen-avb-fuer-lieferungen-und-leistungen/",
      },
    ],
  },

  genericInfo: {
    name: "BAYERISCHE KABELWERKE AG",
    street: "Otto-Schrimpff-Straße 2",
    city: "D – 91154 Roth",
    mailBox: "Postfach 1153",
    mailBoxCity: "D – 91152 Roth",
    mail: "kabel@bayka.de",
    phone: "+49 (0) 9171/806-111",
    fax: "+49 (0) 9171/806-222",
    location: {
      company: "Bayka Berlin GmbH",
      street: "Soltauer Straße 8",
      city: "13509 Berlin",
    },
  },

  generic: {
    salutation: "Anrede",
    firstname: "Vorname",
    lastname: "Nachname",
    name: "Name",
    street: "Straße",
    postcode: "Postleitzahl",
    city: "Stadt",
    phone: "Tel.: ",
    fax: "Fax: ",
    mail: "E-Mail: ",
    password: "Passwort",
    message: "Nachricht",
  },

  UI: {
    dropdown: {
      emptySelection: "Bitte wählen",
    },
  },
};

module.exports = {
  translation,
  langKey: "DE",
};
