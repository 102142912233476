import { useSelector } from "react-redux";
import props from "../../../redux/props";
import {
  HistoryRelativeWrapper,
  StyledHistory,
  Box,
  Text,
  Link,
} from "./History.Styled";
import { getUUID } from "../../../utility";

const History = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  return (
    <HistoryRelativeWrapper>
      <StyledHistory>
        <Box key={getUUID()}>
          <Text>
            {translation.career.history.text}
            <Link href={translation.career.history.link.href}>
              {translation.career.history.link.text}
            </Link>
          </Text>
        </Box>
      </StyledHistory>
    </HistoryRelativeWrapper>
  );
};

export default History;
