import Layout from "../Layout/Layout";
import { StyledContact } from "./Contact.Styled";
// import ContactForm from "./ContactForm/ContactForm";
// import { Container } from "@think-internet/ui-components";
import heroImage from "../../assets/image/contact/hero.jpg";
import { useSelector } from "react-redux";
import props from "../../redux/props";
import Hero from "../SubComponents/Hero/Hero";
import styled from "styled-components";

const Contact = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  const IFrame = styled.iframe`
    border: none;
    width: 100%;
    height: 100vh;
  `;

  return (
    <Layout authRequired={false}>
      <StyledContact>
        <Hero
          title={translation.contact.title}
          by={translation.contact.by}
          bg={heroImage}
          center
        />
        {/* <Container>
          <ContactForm />
        </Container> */}
        <IFrame src="https://mailing.bayka.de/f/145020-380767/" />
      </StyledContact>
    </Layout>
  );
};

export default Contact;
