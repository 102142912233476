import axios from "axios";
import { METHOD } from "../fetch";
import { biteAPIServer, biteAccessKey } from "../utility";

export const getJobs = async () => {
  let request = {
    method: METHOD.POST,
    url: `${biteAPIServer}/api/v1/postings/search`,
    data: {
      key: biteAccessKey,
      channel: 0,
      locale: "de",
      filter: {
        "custom.custom_field1": { in: ["bgf"] },
        // "custom.custom_field1": { in: ["bayka_berlin"] },
      },
      page: {
        num: 1000,
        offset: 0,
      },
      sort: {
        order: "asc",
        by: "title",
      },
    },
  };
  const result = await axios(request);
  if (result.data && result.data.jobPostings) {
    return result.data.jobPostings;
  }
  return false;
};
