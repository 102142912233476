import { useSelector } from "react-redux";
import { StyledLocation, Headline, Split } from "./Location.Styled";
import { Container } from "@think-internet/ui-components";
import props from "../../../redux/props";
import Map from "./Map/Map";
import Directions from "./Directions/Directions";

const Location = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);

  return (
    <StyledLocation>
      <Container>
        <Headline>{translation.aboutUs.location.headline}</Headline>
        <Split>
          <Map />
          <Directions />
        </Split>
      </Container>
    </StyledLocation>
  );
};

export default Location;
