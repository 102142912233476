import directions from "../../../../assets/image/about-us/location/directions.png";
import {
  Content,
  ImageWrapper,
  StyledDirections,
  Image,
  Headline,
} from "./Directions.Styled";
import { useSelector } from "react-redux";
import props from "../../../../redux/props";

const Directions = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);

  return (
    <StyledDirections>
      <Headline>{t.aboutUs.location.directions.headline}</Headline>
      <Content>
        <div>{t.aboutUs.location.directions.name}</div>
        <div>{t.aboutUs.location.directions.street}</div>
        <div>{t.aboutUs.location.directions.city}</div>
      </Content>
      <ImageWrapper>
        <Image src={directions} alt="" />
      </ImageWrapper>
    </StyledDirections>
  );
};

export default Directions;
