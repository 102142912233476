import styled from "styled-components";

export const StyledPeople = styled.div`
  text-align: center;
`;

export const Headline = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.thirdary};
  font-family: bold;
`;

export const SubHeadline = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.thirdary};
  width: 50%;
  margin: 10px auto 15px auto;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const List = styled.div`
  display: flex;
  gap: 100px;
  justify-content: space-around;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    flex-direction: column;
    margin: 100px 25px 0px 25px;
  }
`;

export const Person = styled.div`
  position: relative;
  padding: 125px 0px 20px 0px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(50% - 50px);
    align-items: center;
    padding-top: 0px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
  }
`;

export const Name = styled.div`
  margin-top: 25px;
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.primary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    text-align: center;
  }
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.hint};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    text-align: center;
  }
`;

export const Image = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 150px;
`;

export const Text = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  margin: 25px 0px;
  color: ${({ theme }) => theme.color.font.primary};
`;

export const Value = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 5px 0px;
  color: ${({ theme }) => theme.color.font.primary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    justify-content: center;
  }
`;

export const ValueLink = styled.a`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.font.primary};
  text-decoration: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    justify-content: center;
  }
`;

export const MailIcon = styled.div`
  background-color: ${({ theme }) => theme.color.background.bayka};
  color: ${({ theme }) => theme.color.font.secondary};
  border-radius: 50px;
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 23px;
`;
