import map from "../../../../assets/image/about-us/location/map.png";
import {
  Content,
  ImageWrapper,
  StyledMap,
  Image,
  Headline,
} from "./Map.Styled";
import { useSelector } from "react-redux";
import props from "../../../../redux/props";

const Map = () => {
  const t = useSelector((s) => s[props.TRANSLATION]);

  return (
    <StyledMap>
      <Headline>{t.aboutUs.location.map.headline}</Headline>
      <Content>
        <div>{t.aboutUs.location.map.name}</div>
        <div>{t.aboutUs.location.map.street}</div>
        <div>{t.aboutUs.location.map.city}</div>
      </Content>
      <ImageWrapper>
        <Image src={map} alt="" />
      </ImageWrapper>
    </StyledMap>
  );
};

export default Map;
