import { Container } from "@think-internet/ui-components";
import styled from "styled-components";

export const StyledReasons = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 0px 0 50px 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Headline = styled.div`
  color: ${({ theme }) => theme.color.font.primary};
  font-size: 32px;
  text-align: center;
  font-family: bold;
  width: 100%;
  line-height: 32px;
  margin-top: 50px;
`;
export const PointWrapper = styled.div`
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  margin: 50px 0;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;

export const CTA = styled.a`
  background-color: ${({ theme }) => theme.color.background.bayka};
  color: ${({ theme }) => theme.color.font.secondary};
  padding: 7.5px 25px;
  display: inline-block;
  text-decoration: none;
`;

export const ButtletLine = styled.img`
  margin-top: 100px;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    display: none;
  }
`;

export const GenderHint = styled.div`
  font-size: ${({ theme }) => theme.font.size.xs};
  color: ${({ theme }) => theme.color.font.thirdary};
`;
