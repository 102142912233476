import styled from "styled-components";

export const StyledLocation = styled.div`
  margin: 100px 0;
`;

export const Headline = styled.div`
  font-family: "bold";
`;

export const Split = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
  }
`;
