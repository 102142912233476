import styled from "styled-components";

export const StyledDirections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
`;

export const Headline = styled.div`
  font-family: "bold";
`;

export const Content = styled.div`
  height: 75px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: fit-content;
  }
`;

export const ImageWrapper = styled.div``;

export const Image = styled.img`
  width: 100%;
  aspect-ratio: 16/12;
  object-fit: cover;
`;
